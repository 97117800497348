import React from 'react'

function PlusIcon() {
  return (
    <div>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 18C8.63571 18 8.33057 17.8766 8.08457 17.6297C7.83771 17.3837 7.71429 17.0786 7.71429 16.7143V10.2857H1.28571C0.921429 10.2857 0.615857 10.1623 0.369 9.91543C0.123 9.66943 0 9.36429 0 9C0 8.63571 0.123 8.33014 0.369 8.08329C0.615857 7.83729 0.921429 7.71429 1.28571 7.71429H7.71429V1.28571C7.71429 0.921429 7.83771 0.615857 8.08457 0.369C8.33057 0.123 8.63571 0 9 0C9.36429 0 9.66986 0.123 9.91671 0.369C10.1627 0.615857 10.2857 0.921429 10.2857 1.28571V7.71429H16.7143C17.0786 7.71429 17.3837 7.83729 17.6297 8.08329C17.8766 8.33014 18 8.63571 18 9C18 9.36429 17.8766 9.66943 17.6297 9.91543C17.3837 10.1623 17.0786 10.2857 16.7143 10.2857H10.2857V16.7143C10.2857 17.0786 10.1627 17.3837 9.91671 17.6297C9.66986 17.8766 9.36429 18 9 18Z" fill="#46448D"/>
    </svg>
    </div>
  )
}

export default PlusIcon