
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CustomizedTabs from "./component/tabs";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import React, { useEffect, useState } from "react";
import { getUserListingByKastnameAPI } from "../../Redux/Services/MarketingServices";
import moment from "moment";
// import ReactOwlCarousel from "react-owl-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import ReactOwlCarousel from "react-owl-carousel";\
import SpringModule from "./component/sprint-slider/spring"

// import 'owl.carousel';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, EffectCoverflow, Autoplay } from 'swiper/modules';


const isMobile = window.innerWidth < 991;
const isTablet = window.innerWidth < 768;

const options = {
  loop: true,
  center: true,
  items: 1,
  margin: 10,
  autoplay: true,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 3
    },
    1000: {
      items: 3
    }
  }
};


export const MyListing = ({ visintingDetail, onClick, onClickBusiness }) => {

  const [activeStep, setActiveStep] = React.useState(0);

  const [lisitng_item, setLisitng_item] = useState([]);

  const items = visintingDetail.social_details.filter(_i => _i.type == "K")
  const itemsPerPage = isMobile ? 1 : 3;

  const [page, setPage] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };



  const maxPage = Math.ceil(items.length / itemsPerPage);


  useEffect(() => {
    getUserListingByKastnameAPI(visintingDetail?.kast_name)
      .then((res) => {
        setLisitng_item(res?.data?.listings);
      })
      .catch((err) => {
        console.log("err lisitng", err);
      });
  }, [visintingDetail]);


  const chunkArray = (arrayOfObjects, chunkSize) => {
    return Array.from(
      { length: Math.ceil(arrayOfObjects.length / chunkSize) },
      (_, index) => arrayOfObjects.slice(index * chunkSize, (index + 1) * chunkSize)
    );
  };


  return (
    <Box sx={{ flex: 1, mx: 2, height: "100vh" }}>
      <Grid
        container
        justifyContent="center"
        alignSelf={"center"}
        className="intro"
      >
        <Grid lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ justifyContent: "center", alignSelf: "center" }}>
            <CustomizedTabs theme={visintingDetail.theme} setSelect={(e) => { e == 1 ? onClick() : onClickBusiness() }} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ flex: 1 }}>
        <Grid container sx={{ mt: 0 }}>
          <Grid container xs={12} style={{ alignSelf: "center", flex: 1 }}>
            <Grid xs={0} sm={0} lg={2} md={1} />
            <Grid xs={12} sm={12} lg={8} md={10}>
              <div class="intro">
                <Typography
                  fontSize={isMobile ? 16 : 22}
                  fontWeight={"600"}
                  className="font-poppins"
                  fontFamily={"Poppins"}
                  color={"#fff"}
                >
                  {"Listings & Open Houses"}
                </Typography>
              </div>
              <Grid spacing={0} sx={{ mt: 2, width: window.innerWidth - 30, }} xs={12} justifyContent="center" alignItems="center">
                <Swiper
                  effect={'coverflow'}
                  // grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={isMobile ? 1 : 3}
                  // loop
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  autoplay={isMobile ? {
                    delay: 2500,
                    disableOnInteraction: false,
                  } : {}}
                  // pagination={true}
                  // mousewheel={true}
                  navigation={isMobile ? false : true}
                  modules={isMobile ? [EffectCoverflow, Pagination, Navigation, Mousewheel, Autoplay] : [EffectCoverflow, Pagination, Navigation, Mousewheel]}
                  className="mySwiper">
                  {chunkArray(items, 1).map((data) => {
                    let data_detail = lisitng_item?.filter(
                      (i) => i.listing_title === data[0].title
                    )[0]
                    if (data_detail?.listing_title) {
                      return <SwiperSlide key={data_detail?.id}>{
                        <Card data_detail={data_detail} visintingDetail={visintingDetail} />
                      }</SwiperSlide>
                    }
                  })}
                </Swiper>
              </Grid>
            </Grid>
            <Grid xs={0} sm={0} lg={2} md={1} />
          </Grid>
        </Grid>
      </Box>

    </Box >
  );
};


const Card = ({ data_detail, visintingDetail }) => {
  // item lg={4} md={3} xs={12} 
  return <Box
    onClick={() => { window.location.href = "/" + visintingDetail?.first_name + `/listing/${data_detail?.listing_title}` }}
    sx={{
      flex: 1,
      background:
        visintingDetail.theme == "2" ? "linear-gradient(to right, #0B172E, #344C7E)" : "linear-gradient(40deg, #4643BE , #8A58F5 )",
      border: "0.5px solid #2C5097",
      borderRadius: 5,
      mx: 2,
      cursor: "pointer"
    }}
  >
    <div>
      <Box
        style={{
          backgroundImage: `url(${data_detail?.image.split(",")[0]})`,
          height: 200,
          backgroundSize: "cover",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {data_detail?.holding_open_house == "1" && moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ?
          <Box
            sx={{
              background: "#4478FF",
              width: 100,
              p: 1,
              height: 25,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
              justifyContent: "center",
              display: "flex"
            }}
          >
            <Typography
              fontSize={isMobile ? 14 : 14}
              sx={{
                // justifyContent: "center",
                alignSelf: "center",
              }}
              color="#fff"
            >
              {"Open House"}
            </Typography>
          </Box> : null}
      </Box>
    </div >

    <Box sx={{ p: 2 }}>
      <Typography
        fontSize={isMobile ? 16 : 18}
        mt={2}
        fontWeight={"600"}
        className="font-poppins"
        fontFamily={"Poppins"}
        color={"#fff"}
        sx={{ height: isMobile ? 30 : 50 }}
      >
        {data_detail?.listing_title.length > 40 ? (data_detail?.listing_title.substring(0, 40) + "...") : data_detail?.listing_title}
      </Typography>

      {data_detail?.holding_open_house == "1" && moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ?

        <Typography
          fontSize={isMobile ? 12 : 14}
          fontWeight={"600"}
          className="font-poppins"
          fontFamily={"Poppins"}
          color={"#fff"}
          sx={{ mt: 2 }}
        >
          {"Open House"}
        </Typography> : null}

      {data_detail?.holding_open_house == "1" && moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ?
        <div
          style={{
            flexDirection: "row",
            alignSelf: "center",
            flex: 1,
            display: "flex",
            padding: 2,
          }}
        >
          <svg
            width={isMobile ? "24" : "30"}
            height={isMobile ? "24" : "40"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.88">
              <path
                d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C6.47 22 2 17.5 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z"
                fill="#729AFF"
              />
            </g>
          </svg>
          <Typography
            fontSize={isMobile ? 12 : 14}
            sx={{ p: 0.3, mx: 1, mt: isMobile ? 0 : 0.8, height: isMobile ? 30 : 50 }}
            fontWeight={"bold"}
            fontFamily={"Poppins"}
            color={"#fff"}
          >

            {moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? moment(data_detail?.date, 'DD/MM/YYYY').format('LLLL') : "-"}

            {moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? " | " : ""}{moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? data_detail?.time_fram : ""}
          </Typography>
        </div> : <div style={{ height: 30 }}></div>}


      <div
        style={{
          flexDirection: "row",
          alignSelf: "center",
          flex: 1,
          display: "flex",
          padding: 2,
        }}
      >
        <svg
          width={isMobile ? "24" : "30"}
          height={isMobile ? "24" : "40"}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.88">
            <path
              d="M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.8 22 12 22C12.2 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 19.7C9.9 17.7 6 13.4 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 13.3 14.1 17.7 12 19.7ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z"
              fill="#729AFF"
            />
          </g>
        </svg>

        <Typography
          fontSize={isMobile ? 12 : 14}
          sx={{ p: 0.3, mx: 1, mt: isMobile ? 0 : 0.8, height: isMobile ? 30 : 50 }}
          fontWeight={"bold"}
          fontFamily={"Poppins"}
          color={"#fff"}
        >
          {data_detail?.address}
        </Typography>
      </div>
      <Typography
        fontSize={isMobile ? 10 : 12}
        fontWeight={"600"}
        className="font-poppins"
        fontFamily={"Poppins"}
        color={"#fff"}
      >

        {"$"}{parseInt(data_detail?.sales_prices).toLocaleString("en-US") ?? ""}
      </Typography>
      <Typography
        fontSize={isMobile ? 10 : 12}
        fontWeight={"600"}
        className="font-poppins"
        fontFamily={"Poppins"}
        color={"#fff"}
      >
        {data_detail?.bedrooms} bed |  {data_detail?.bathroom} bath.
      </Typography>
    </Box>
  </Box >

}

export const ListingCard = ({ item, lisitng_item }) => {

  return (
    <Box
      sx={{
        flex: 1,
        background: "linear-gradient(to right, #0B172E, #344C7E)",
        border: "0.5px solid #2C5097",
        borderRadius: 5,
      }}
    >
      <div>
        <Box
          style={{
            backgroundImage:
              "Url(https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?cs=srgb&dl=pexels-vecislavas-popa-1571460.jpg&fm=jpg)",
            height: 200,
            backgroundSize: "cover",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Box
            sx={{
              background: "#4478FF",
              width: 100,
              p: 1,
              height: 25,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <Typography
              fontSize={14}
              sx={{ justifyContent: "center", alignSelf: "center" }}
              color="#fff"
            >
              {item?.title}
            </Typography>
          </Box>
        </Box>
      </div>

      <Box sx={{ p: 2 }}>
        <Typography
          fontSize={isMobile ? 16 : 18}
          mt={2}
          fontWeight={"600"}
          className="font-poppins"
          fontFamily={"Poppins"}
          color={"#fff"}
        >
          {"300 E 54th Street Unit: 26-EF"}
        </Typography>
        <Typography
          fontSize={isMobile ? 12 : 14}
          fontWeight={"600"}
          className="font-poppins"
          fontFamily={"Poppins"}
          color={"#fff"}
        >
          {"Open House"}
        </Typography>
        <div
          style={{
            flexDirection: "row",
            alignSelf: "center",
            flex: 1,
            display: "flex",
            padding: 2,
          }}
        >
          <svg
            width={isMobile ? "24" : "30"}
            height={isMobile ? "24" : "40"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.88">
              <path
                d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C6.47 22 2 17.5 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z"
                fill="#729AFF"
              />
            </g>
          </svg>
          <Typography
            fontSize={isMobile ? 12 : 14}
            sx={{ p: 0.3, mx: 1, mt: isMobile ? 0 : 0.8 }}
            fontWeight={"bold"}
            fontFamily={"Poppins"}
            color={"#fff"}
          >
            {"Saturday, Aug 26  |  12p-6p"}
          </Typography>
        </div>
        <div
          style={{
            flexDirection: "row",
            alignSelf: "center",
            flex: 1,
            display: "flex",
            padding: 2,
          }}
        >
          <svg
            width={isMobile ? "24" : "30"}
            height={isMobile ? "24" : "40"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.88">
              <path
                d="M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.8 22 12 22C12.2 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 19.7C9.9 17.7 6 13.4 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 13.3 14.1 17.7 12 19.7ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z"
                fill="#729AFF"
              />
            </g>
          </svg>

          <Typography
            fontSize={isMobile ? 12 : 14}
            sx={{ p: 0.3, mx: 1, mt: isMobile ? 0 : 0.8 }}
            fontWeight={"bold"}
            fontFamily={"Poppins"}
            color={"#fff"}
          >
            {"300 E 54th Street, New York"}
          </Typography>
        </div>
        <Typography
          fontSize={isMobile ? 10 : 12}
          fontWeight={"600"}
          className="font-poppins"
          fontFamily={"Poppins"}
          color={"#fff"}
        >
          {"$1,475,000"}
        </Typography>
        <Typography
          fontSize={isMobile ? 10 : 12}
          fontWeight={"600"}
          className="font-poppins"
          fontFamily={"Poppins"}
          color={"#fff"}
        >
          {"2 bed  |  2 bath  |  1,575 Sq.Ft. "}
        </Typography>
      </Box>
    </Box>
  );
};
