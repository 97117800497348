import React, { useState } from "react"
import { Box, Button, Grid, Typography, TextField, Snackbar, IconButton, Alert, CircularProgress } from "@mui/material"
import CustomizedTabs from "./component/tabs"
import { CreateLeadWithoutTokenService } from "../../Redux/Services/service"

const isMobile = window.innerWidth < 991
const isTablet = window.innerWidth < 768

export const NewsLetter = ({ visintingDetail, onClick, onClickBusiness }) => {


    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("")
    const [severity, setSeverity] = useState("info")
    const [snackMsg, setSnackMsg] = useState("")

    const handleLoginEmailFormValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (!email || email == "") {
            formIsValid = false;
            formErrors["email_error"] = "Email field is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formIsValid = false;
            formErrors["email_error"] = "Invalid Email id.";
        }
        return formIsValid;
    };

    const submitLead = () => {

        if (handleLoginEmailFormValidation()) {
            setLoading(true);
            const data = {
                type: "O",
                lead_type: "C",
                first_name: "none",
                last_name: "none",
                mobile_1: "none",
                mobile_2: "",
                mobile_3: "",
                email: email,
                email_2: "",
                address_1: "",
                address_2: "",
                full_address: "",
                city: "",
                state: "",
                zip_code: "",
                source_id: 64,
                agent_id: "",
                listing_agent: "",
                company_attorney: "",
                loan_officer: "",
                insurance_agent: "",
                label: "label",
                notes: "",
                price_range: "",
                target_city: "",
                closing_date: "",
                bedroom: "",
                bathroom: "",
                garage: "",
                custom_tag: "",
                nurture: "",
                tag: "",
                summary: "",
                openfield1: "",
                openfield2: "",
                openfield3: "",
                openfield4: "",
                openfield5: "",
                openfield6: "",
                openfield7: "",
                openfield8: "",
                openfield9: "",
                openfield10: "",
                is_active: "Y",
                is_arch: "N",
                is_verified: "N",
                created_by: visintingDetail.created_by
            }

            CreateLeadWithoutTokenService(data)
                .then((res) => {
                    if (res.status === 200) {
                        setOpen(true)
                        setSeverity("success")
                        setSnackMsg("Successfully added!")
                        setTimeout(() => {
                            setLoading(false);
                            setOpen(false)
                        }, 3000);
                        setEmail("")
                    }
                })
                .catch((err) => {
                    setOpen(true)
                    setSeverity("error")
                    setSnackMsg("Error!")
                    setLoading(false)
                    setTimeout(() => {
                        setLoading(false);
                        setOpen(false)
                    }, 3000);
                    //.log("error", err);
                });
            // setLoading(false);

        }

    }



    return <Box sx={{ flex: 1, mx: 2, height: "100vh" }}>
        <Grid container justifyContent="center" alignSelf={"center"} className="intro" >
            <Grid lg={6} item md={6} sm={12} xs={12} >
                <Box sx={{ justifyContent: "center", alignSelf: "center", }}>
                    <CustomizedTabs theme={visintingDetail.theme} onClickBusiness={onClickBusiness} setSelect={(e) => { e == 1 ? onClick() : onClickBusiness() }} />
                </Box>
            </Grid>
        </Grid>

        <Grid container xs={12} justifyContent={"center"} alignSelf={"center"} sx={{ my: isMobile ? 1 : 5, flex: 10 }} >
            <ProfileSection visintingDetail={visintingDetail} />
            <div class="intro">
                <Typography color={"#fff"} fontSize={isMobile ? "16px" : "42px"} fontFamily={"Poppins"} fontWeight={"800"} sx={{ my: 0, textAlign: "center", mt: isMobile ? 2 : 0 }} >{"Subscribe to my newsletter"}</Typography>
            </div>
        </Grid>

        <Grid xs={12} alignSelf={"center"} sx={{ my: isMobile ? 2 : 10 }} className="intro newsletters">
            {visintingDetail.theme == "2" ?
                <NewsLetterInput submitLead={submitLead} isLoading={loading} email={email} setEmail={setEmail} /> :
                <NewsLetterInputLight submitLead={submitLead} isLoading={loading} email={email} setEmail={setEmail} />}
        </Grid>

        <Snackbar style={{ position: "absolute" }} anchorOrigin={{ vertical: 'center', horizontal: 'center', }} open={open} action={
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    x
                </IconButton>
            </React.Fragment>
        } autoHideDuration={10000} sx={{ zIndex: 100 }}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {snackMsg}
            </Alert>
        </Snackbar>
    </Box>
}


const ProfileSection = ({ visintingDetail }) => {
    return <Grid container justifyContent={"center"} alignSelf={"center"} sx={{ my: isMobile ? 0 : 5 }} className="intro">
        <Grid item lg={5} xs={12} display={"flex"} justifyContent={isMobile ? "center" : "flex-end"} alignSelf={isMobile ? "center" : "flex-end"}>
            <img loading="lazy" src={visintingDetail.profile_photo} style={{ width: 150, height: 150, borderRadius: 10, objectFit: "cover", }} />
        </Grid>
        <Grid item lg={5} xs={12} sx={{ mx: 2 }} alignSelf={"center"}>
            <Typography color={"#fff"} fontSize={"24px"} fontFamily={"Poppins"} fontWeight={"800"} sx={{ mt: 0, textAlign: isMobile ? "center" : "left" }} >{visintingDetail?.first_name}</Typography>
            <Typography color={"#fff"} fontSize={isMobile ? "12px" : "18px"} fontFamily={"Poppins"} fontWeight={"800"} sx={{ mt: 1, textAlign: isMobile ? "center" : "left" }} >{visintingDetail?.job_title}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: isMobile ? "center" : "flex-start" }}>
                <Typography color={"#fff"} textDecorationLine="underline" fontSize={"16px"} fontFamily={"Poppins"} fontWeight={"800"} sx={{ my: 0, mt: 1, cursor: "pointer", textDecorationLine: "underline" }} onClick={() => { window.location.href = visintingDetail?.company_website }} >
                    {"Visit Website"}</Typography>
            </Box>
        </Grid>
    </Grid>
}

const NewsLetterInput = ({ submitLead, setEmail, email, isLoading }) => {
    return <Grid container justifyContent={"center"} alignSelf={"center"} sx={{ flex: 1 }}>
        <TextField onChange={(e) => { setEmail(e.target.value) }} value={email} InputProps={{
            style: {
                borderRadius: '10px', // Set the border radius here
                background: "linear-gradient(25deg, #0B172E, #344C7E)",
                color: "#fff",
                width: 300
            },
        }} variant="outlined" style={{ background: "rgba(0,0,0,0)" }} placeholder="Enter email..." />
        <Button onClick={() => isLoading ? null : submitLead()} sx={{ backgroundImage: "linear-gradient(45deg, #7D26EF, #4942F3)", backgroundSize: '200% auto', color: "#fff", padding: 2, width: isMobile ? "150px" : 150, mx: 5, mt: isMobile ? 1 : 0 }}>{isLoading ? <CircularProgress size={20} /> : "Submit"}</Button>
    </Grid>
}

const NewsLetterInputLight = ({ submitLead, setEmail, email, isLoading }) => {
    return <Grid container justifyContent={"center"} alignSelf={"center"} sx={{ flex: 1 }}>
        <TextField onChange={(e) => { setEmail(e.target.value) }} value={email} InputProps={{
            style: {
                borderRadius: '10px', // Set the border radius here
                background: "#fff",
                color: "#000",
                width: 300
            },
        }} variant="outlined" style={{ background: "rgba(0,0,0,0)" }} placeholder="Enter email..." />
        <Button onClick={() => isLoading ? null : submitLead()} sx={{ backgroundImage: "linear-gradient(45deg, #7D26EF, #4942F3)", backgroundSize: '200% auto', color: "#fff", padding: 2, width: isMobile ? "150px" : 150, mx: 5, mt: isMobile ? 1 : 0 }}>{isLoading ? <CircularProgress size={20} /> : "Submit"}</Button>
    </Grid>
}