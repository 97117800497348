import React from 'react'

function HeartEmoji() {
    return (
        <div>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 4.84489C17 10.3149 9.12308 14.7425 8.78763 14.9254C8.69922 14.9744 8.60039 15 8.5 15C8.39961 15 8.30078 14.9744 8.21237 14.9254C7.87692 14.7425 0 10.3149 0 4.84489C0.0014061 3.56039 0.497599 2.32892 1.37972 1.42064C2.26184 0.512355 3.45785 0.00144779 4.70536 0C6.27254 0 7.64469 0.693914 8.5 1.86685C9.35531 0.693914 10.7275 0 12.2946 0C13.5421 0.00144779 14.7382 0.512355 15.6203 1.42064C16.5024 2.32892 16.9986 3.56039 17 4.84489Z" fill="#FF3700" />
            </svg>
        </div>
    )
}

export default HeartEmoji