import React from "react";

function MessageIcon() {
  return (
    <div>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.172 8.834L10.002 10.769L12.73 8.887L18.845 14.92C18.684 14.972 18.512 15 18.333 15H1.667C1.447 15 1.237 14.957 1.044 14.88L7.172 8.834ZM20 3.876V13.333C20 13.58 19.946 13.814 19.85 14.025L13.856 8.111L20 3.876ZM9.43602e-07 3.929L6.042 8.061L0.106001 13.919C0.0356967 13.7316 -0.000212233 13.5331 9.43602e-07 13.333V3.93V3.929ZM18.333 0C19.253 0 20 0.746 20 1.667V2.253L9.998 9.148L9.43602e-07 2.31V1.667C9.43602e-07 0.747 0.746001 0 1.667 0H18.333Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default MessageIcon;
