import {
    UserLoginConst, UserSignupConst, ViewLeadsConst,
    AllVerifiedLeadsConst, AllUnVerifiedLeadsConst,
    AddLeadConst, UserEmailPasswordSignupConst,
    ArchiveLeadsConst, AddFromBizoodaLeadConst,
    BasicInformationFromConst, DetailedInformationFormConst,
    AgentInformationFormConst, LeadRequirementFormConst,
    AddNewLeadConst, GetAllInsuranceAgentConst,
    GetAllNurtureConst, GetAllClosingAgentConst,
    GetAllCompanyAttorneyConst, GetAllLoanAgentConst,
    GetAllAgentConst, GetAllSourceConst, GetAllTagConst,
    GetLeadByIdConst, GetDashBoardConst


} from '../Constant/constant';

const initTheme =
{
    User: [],
    Signup: [],
    Lead: [],
    Leads: [],
    AllVerifiedLead: [],
    AllUnVerifiedLead: [],
    ArchiveLead: [],
    GetLeadFromBizooda: [],
    BasicInformationFromData: [],
    DetailedInformationFormData: [],
    AgentInformationFormData: [],
    LeadRequirementFormData: [],
    AddNewLeadData: [],
    GetAllInsuranceAgentData: [],
    GetAllNurtureData: [],
    GetAllClosingAgentData: [],
    GetAllCompanyAttorneyData: [],
    GetAllLoanAgentData: [],
    GetAllAgentData: [],
    GetAllSourceData: [],
    GetAllTagData: [],
    leadByIdData: [],
    GetDashBoardData: []









};

const RecuitorFlowAuth = (state = initTheme, action) => {
    //.log(state)
    switch (action.type) {

        case UserLoginConst:
            return { ...state, User: action.payload }

        case UserEmailPasswordSignupConst:
            return { ...state, Signup: action.payload }

        case UserSignupConst:
            return { ...state, User: action.payload }

        case ViewLeadsConst:
            return { ...state, Leads: action.payload }

        case AllVerifiedLeadsConst:
            return { ...state, AllVerifiedLead: action.payload }

        case AllUnVerifiedLeadsConst:
            return { ...state, AllUnVerifiedLead: action.payload }

        case ArchiveLeadsConst:
            return { ...state, ArchiveLead: action.payload }

        case AddLeadConst:
            return { ...state, Lead: action.payload }

        case AddFromBizoodaLeadConst:
            return { ...state, GetLeadFromBizooda: action.payload }

        case BasicInformationFromConst:
            return { ...state, BasicInformationFromData: action.payload }

        case DetailedInformationFormConst:
            return { ...state, DetailedInformationFormData: action.payload }

        case AgentInformationFormConst:
            return { ...state, AgentInformationFormData: action.payload }

        case LeadRequirementFormConst:
            return { ...state, LeadRequirementFormData: action.payload }

        case AddNewLeadConst:
            return { ...state, AddNewLeadData: action.payload }

        case GetAllInsuranceAgentConst:
            return { ...state, GetAllInsuranceAgentData: action.payload }

        case GetAllNurtureConst:
            return { ...state, GetAllNurtureData: action.payload }

        case GetAllClosingAgentConst:
            return { ...state, GetAllClosingAgentData: action.payload }

        case GetAllCompanyAttorneyConst:
            return { ...state, GetAllCompanyAttorneyData: action.payload }

        case GetAllLoanAgentConst:
            return { ...state, GetAllLoanAgentData: action.payload }

        case GetAllAgentConst:
            return { ...state, GetAllAgentData: action.payload }

        case GetAllSourceConst:
            return { ...state, GetAllSourceData: action.payload }

        case GetAllTagConst:
            return { ...state, GetAllTagData: action.payload }

        case GetLeadByIdConst:
            return { ...state, leadByIdData: action.payload }

        case GetDashBoardConst:
            return { ...state, GetDashBoardData: action.payload }





        default:
            return state;
    }
};

export default RecuitorFlowAuth
