import React from "react";

function FacebookIcon(props) {
  return (
    <div>
      <svg
        width={props.width ?? "72"}
        height={props.height ?? "74"}
        viewBox="0 0 72 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1068_17)">
          <path
            d="M71.9286 36.5643C71.9286 16.4099 56.009 0.0714111 36.3714 0.0714111C16.7339 0.0714111 0.81427 16.4097 0.81427 36.5643C0.81427 54.7788 13.8171 69.8763 30.8156 72.6138V47.113H21.7874V36.5643H30.8156V28.5244C30.8156 19.3784 36.1242 14.3264 44.2459 14.3264C48.1364 14.3264 52.2055 15.0392 52.2055 15.0392V24.0198H47.7219C43.3045 24.0198 41.9272 26.8329 41.9272 29.719V36.5643H51.7888L50.2123 47.113H41.9272V72.6138C58.9258 69.8763 71.9286 54.7791 71.9286 36.5643Z"
            fill="#1877F2"
          />
          <path
            d="M50.2123 47.113L51.7888 36.5643H41.9272V29.719C41.9272 26.8326 43.3048 24.0198 47.7219 24.0198H52.2054V15.0392C52.2054 15.0392 48.1364 14.3264 44.2459 14.3264C36.1242 14.3264 30.8156 19.3784 30.8156 28.5244V36.5643H21.7874V47.113H30.8156V72.6138C32.6535 72.9094 34.5111 73.0576 36.3714 73.0571C38.2317 73.0577 40.0893 72.9095 41.9272 72.6138V47.113H50.2123Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1068_17">
            <rect
              width="71.1143"
              height="72.9857"
              fill="white"
              transform="translate(0.81427 0.0714111)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default FacebookIcon;
