export const countryCodes = [
  { label: "+1", value: "+1" },
  // { label: "+91", value: "+91" },
  // { label: "+44", value: "+44" },
  // { label: "+61", value: "+61" },
  // { label: "+86", value: "+86" },
  // { label: "+81", value: "+81" },
  // { label: "+49", value: "+49" },
  // { label: "+33", value: "+33" },
  // { label: "+7", value: "+7" },
  // { label: "+55", value: "+55" },
  // { label: "+82", value: "+82" },
  // { label: "+52", value: "+52" },
  // { label: "+39", value: "+39" },
  // { label: "+34", value: "+34" },
  // { label: "+90", value: "+90" },
  // { label: "+62", value: "+62" },
  // { label: "+31", value: "+31" },
  // { label: "+966", value: "+966" },
  // { label: "+41", value: "+41" },
  // { label: "+65", value: "+65" },
  // { label: "+54", value: "+54" },
  // { label: "+46", value: "+46" },
  // { label: "+48", value: "+48" },
  // { label: "+32", value: "+32" },
  // { label: "+47", value: "+47" },
  // { label: "+43", value: "+43" },
  // { label: "+971", value: "+971" },
  // { label: "+60", value: "+60" },
  // { label: "+30", value: "+30" },
  // { label: "+27", value: "+27" },
  // { label: "+66", value: "+66" },
  // { label: "+45", value: "+45" },
  // { label: "+852", value: "+852" },
  // { label: "+358", value: "+358" },
  // { label: "+351", value: "+351" },
  // { label: "+20", value: "+20" },
  // { label: "+353", value: "+353" },
  // { label: "+64", value: "+64" },
  // { label: "+84", value: "+84" },
  // { label: "+63", value: "+63" },
  // { label: "+972", value: "+972" },
  // { label: "+57", value: "+57" },
  // { label: "+56", value: "+56" },
  // { label: "+92", value: "+92" },
  // { label: "+40", value: "+40" },
  // { label: "+88 ", value: "+88 " },
  // { label: "+51", value: "+51" },
  // { label: "+420", value: "+420" },
  // { label: "+36", value: "+36" },
  // { label: "+380", value: "+380" },
  // { label: "+421", value: "+421" },
  // { label: "+852", value: "+852" },
  // { label: "+886", value: "+886" },
  // { label: "+359", value: "+359" },
  // { label: "+381", value: "+381" },
  // { label: "+385", value: "+385" },
];
