import React from "react";

function VisitingLinkdin(props) {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.88577 13.2464H0.0472412V3.78467H2.88577V13.2464ZM13.2937 13.2464H10.4552V8.19195C10.4552 6.87487 9.98586 6.21918 9.05577 6.21918C8.3187 6.21918 7.85129 6.58629 7.61664 7.32147V13.2464H4.77811C4.77811 13.2464 4.81596 4.73084 4.77811 3.78467H7.01866L7.19181 5.67702H7.25047C7.83237 4.73084 8.76246 4.08934 10.0379 4.08934C11.0077 4.08934 11.7921 4.359 12.391 5.03646C12.9937 5.71486 13.2937 6.62508 13.2937 7.90337V13.2464Z"
          fill="white"
        />
        <path
          d="M1.46657 2.83852C2.27654 2.83852 2.93314 2.2031 2.93314 1.41926C2.93314 0.635425 2.27654 0 1.46657 0C0.656606 0 0 0.635425 0 1.41926C0 2.2031 0.656606 2.83852 1.46657 2.83852Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default VisitingLinkdin;
