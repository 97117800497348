import React from "react";

function VisitingWebsite(props) {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0384 10.9181C21.0384 5.44034 16.598 1 11.1203 1C5.64249 1 1.20215 5.44034 1.20215 10.9181C1.20215 16.3959 5.64249 20.8362 11.1203 20.8362"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.112 1.0498C12.112 1.0498 15.0875 4.96746 15.0875 10.9183M10.1284 20.7868C10.1284 20.7868 7.15299 16.8692 7.15299 10.9183C7.15299 4.96746 10.1284 1.0498 10.1284 1.0498M1.82697 14.3897H11.1202M1.82697 7.44699H20.4135"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9183 16.7856C21.4082 17.0871 21.3775 17.82 20.8736 17.8776L18.3276 18.1662L17.1861 20.4593C16.9599 20.9145 16.2607 20.6913 16.1447 20.128L14.8999 14.0621C14.8018 13.586 15.2302 13.2865 15.6438 13.5414L20.9183 16.7856Z"
          stroke="white"
        />
      </svg>
    </div>
  );
}

export default VisitingWebsite;
