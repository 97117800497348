import React from 'react';
import { Container, Grid, Typography, Card, CardContent, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import Fade from 'react-reveal';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, EffectCoverflow, Autoplay } from 'swiper/modules';
import moment from 'moment';
const isMobile = window.innerWidth < 991;


const TestimonialSection = ({ lisitng_item, cardDetails }) => {

    return (
        <Container sx={{ padding: '0rem 0' }}>

            {/* Testimonial Slider */}
            <Grid container justifyContent="center" sx={{ mt: 5, my: 2 }}>
                <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={30}
                    slidesPerView={isMobile ? 1 : 3}
                    pagination={{ clickable: true, type: "bullets", }}
                    loop={true}
                    style={{ paddingBottom: '60px' }}

                >
                    {cardDetails?.social_details?.map((_item, i) => {
                        let data_detail = lisitng_item?.filter(
                            (i) => i.listing_title === _item.title
                        )[0];
                        if (_item.type === "K" && lisitng_item?.filter(
                            (i) => i.listing_title === _item.title
                        ).length > 0) {

                            return <SwiperSlide key={i} >
                                <div
                                    key={i}
                                    class=""
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                    onClick={() => { window.location.href = "/" + cardDetails?.first_name + `/listing/${data_detail?.listing_title}` }}
                                >
                                    <div class="books__book h-100 translateEffect1" style={{ minHeight: 450 }}>
                                        <a
                                            data-glightbox=" description: .custom-desc1; descPosition: right;"
                                            class="glightbox2"
                                            href="lightTheme/assets/images/book3.png"
                                        >
                                            <img
                                                class="img-fluid"
                                                src={data_detail?.image.split(",")[0] ?? "lightTheme/assets/images/book3.png"}
                                                alt="Book 3"
                                            />
                                        </a>
                                        {data_detail?.holding_open_house == "1" && moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ?
                                            <div
                                                style={{
                                                    flexDirection: "row",
                                                    alignSelf: "center",
                                                    flex: 1,
                                                    display: "flex",
                                                    padding: 2,
                                                    height: 60
                                                }}
                                            >
                                                <svg
                                                    width={isMobile ? "24" : "30"}
                                                    height={isMobile ? "24" : "40"}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.88">
                                                        <path
                                                            d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C6.47 22 2 17.5 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z"
                                                            fill="#000000"
                                                        />
                                                    </g>
                                                </svg>
                                                <Typography
                                                    fontSize={isMobile ? 12 : 14}
                                                    sx={{ p: 0.3, mx: 1, mt: isMobile ? 0 : 0.8, height: isMobile ? 30 : 50 }}
                                                    fontWeight={"bold"}
                                                    fontFamily={"Poppins"}
                                                >

                                                    {moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? moment(data_detail?.date, 'DD/MM/YYYY').format('LLLL') : "-"}

                                                    {moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? " | " : ""}{moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ? data_detail?.time_fram : ""}
                                                </Typography>
                                            </div> : <div style={{ height: 60 }}><span>{"No Open House"}</span></div>}
                                        <Grid flexDirection={"row"} container xs={{}} className="row d-flex">
                                            <Grid item xs={1}>
                                                <i className="icofont-location-pin"></i>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <p className="books__book__subtitle mb-0">
                                                    {data_detail?.address}
                                                </p>
                                            </Grid>
                                        </Grid>

                                        <a
                                            data-glightbox=" description: .custom-desc1; descPosition: right;"
                                            class="glightbox2"
                                            href="#"
                                        >
                                            <h5 class="books__book__title">  {_item?.title}</h5>
                                        </a>
                                        <div class="d-flex justify-content-between">
                                            <span>{"$"}{parseInt(data_detail?.sales_prices).toLocaleString("en-US") ?? ""}</span>
                                            <Typography
                                                fontWeight={"600"}
                                                className="font-poppins"
                                                fontFamily={"Poppins"}
                                                style={{}}
                                            >
                                                {data_detail?.bedrooms} bed |  {data_detail?.bathroom} bath.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        }

                    })}
                </Swiper>
                {/* </Grid> */}
            </Grid>
        </Container>
    );
};

export default TestimonialSection;
