import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,

} from "@mui/material";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Introduction } from "./introduction";
import { FunnelPreview } from "./funnel-preview";
import { ExternalSection } from "./external-link";
import { NewsLetter } from "./news-letter";
import { MyListing } from "./my-listing";
import { IntroductionNew } from "./introduction-new";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import moment from "moment";
import { sendNotificationAPI } from "../../Redux/Services/MarketingServices";


const isMobile = window.innerWidth < 991;
function KastPreview({ visintingDetail, mainFunnelData }) {
  const { kastId } = useParams();
  const [loading, setLoading] = React.useState(false);

  const sectionRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const currentSectionIndex = useRef(0);
  const [index, setIndex] = useState(0)
  const lastScrollTimeRef = useRef(0);

  const scrollableDivRef = useRef(null);
  const buttonRef = useRef(null);

  const scrollToNextSection = () => {

    const allbullet = document.getElementsByClassName("swiper-pagination-bullet");

    for (let i = 0; i < allbullet.length; i++) {
      if (Array.from(allbullet[i].classList).includes("swiper-pagination-bullet-active")) {
        if (i != allbullet.length - 1) {
          allbullet[i + 1].click()
        } else {
          allbullet[i - 4].click();
        }
      }
    }

  };

  const onClick = () => {
    sectionRefs[1].current.scrollIntoView({
      behavior: "smooth"
    })
  }
  const onClickBusiness = () => {
    sectionRefs[0].current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const getName = (index) => {
    switch (index) {
      case 0:
        return "Business Card"
      case 1:
        return "External Links"
      case 2:
        return "Listings"
      case 3:
        return "Downloads"
      case 4:
        return "News Letter"
    }
  }


  return (
    <>
      {loading === true ? (
        <div
          style={{
            height: "100%",
            placeItems: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="kast-body scroll"
          style={{ height: isMobile ? "100vh" : "100vh" }}
          id="bob"
          ref={scrollableDivRef}
        >
          {/* <VerticalScrollBullet bulletlength={[1, 2, 3, 4, 5]} currentIndex={index} /> */}

          <div class={visintingDetail.theme == "2" ? "overlay" : "overlay-white"} />
          <style>
            {visintingDetail?.theme == 2 ? `` : `
          * {
            font-family: "Poppins" !important;
          }
          `}
          </style>


          <div
            className="arrow"
            style={{
              position: "fixed",
              bottom: 5,
              left: 0,
              right: 0,
              borderRadius: 100,
              padding: 5,
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              color: "#fff",
              fontSize: 10,
              cursor: "pointer",
              zIndex: 999999
            }}
            id="arrow"
            onClick={scrollToNextSection}
            ref={buttonRef}
          >
            <svg
              fill="#fff"
              width={isMobile ? "40px" : "40px"}
              height={isMobile ? "40px" : "40px"}
              viewBox="0 -6 524 524"
              xmlns="http://www.w3.org/2000/svg"
              id="arrow"
            >
              {/* <title>down</title> */}
              <path
                d="M64 191L98 157 262 320 426 157 460 191 262 387 64 191Z"
                fill="#fff"
              />
            </svg>
            {/* Swipe down for More */}
          </div>

          <Swiper direction={'vertical'} pagination={{
            clickable: true,
            scrollToNextSection: true,
            renderBullet: function (index, className) {
              return '<span class=" tooltip ' + className + '">' + ("") + '<span class="tooltiptext">' + getName(index) + '</span></span>'
            },
          }}
            cssMode={true}
            // navigation={true}
            mousewheel={true}
            keyboard={true}
            className="mySwiper"
            modules={[Pagination]}>

            <SwiperSlide>

              <section class="section" style={{ display: "flex", justifyContent: "center", alignItems: "center", }} ref={sectionRefs[0]}>
                <Box
                  sx={{
                    margin: 0,
                    padding: 0,
                    justifyContent: "cenetr",
                    alignSelf: "center",
                    display: "flex",
                    marginTop: isMobile ? -20 : 0
                  }}
                  className=""
                >
                  {!isMobile ?
                    <IntroductionNew visintingDetail={visintingDetail} /> :
                    <Introduction visintingDetail={visintingDetail} />}

                </Box>
              </section>
            </SwiperSlide>

            {visintingDetail.social_details.filter(_i => _i.type == "E").length == 0 ? null :
              <SwiperSlide>

                <section class="section" ref={sectionRefs[1]}>
                  <Box
                    sx={{
                      margin: 0,
                      padding: 0,
                      justifyContent: "cenetr",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <ExternalSection onClickBusiness={onClickBusiness} onClick={onClick} visintingDetail={visintingDetail} />
                  </Box>
                </section>
              </SwiperSlide>}

            {visintingDetail.social_details.filter(_i => _i.type == "K").length == 0 ? null :
              <SwiperSlide>
                <section class="section" ref={sectionRefs[2]}>
                  <Box
                    sx={{
                      margin: 0,
                      padding: 0,
                      justifyContent: "cenetr",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <MyListing onClickBusiness={onClickBusiness} onClick={onClick} visintingDetail={visintingDetail} />
                  </Box>
                </section>
              </SwiperSlide>}
            {visintingDetail.social_details.filter(_i => _i.type == "R").length == 0 ? null :
              <SwiperSlide>
                <section class="section" ref={sectionRefs[3]}>
                  <Box
                    sx={{
                      margin: 0,
                      padding: 0,
                      justifyContent: "cenetr",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <FunnelPreview
                      mainFunnelData={mainFunnelData}
                      visintingDetail={visintingDetail}
                      onClick={onClick}
                      onClickBusiness={onClickBusiness}
                    />
                  </Box>
                </section>

              </SwiperSlide>}
            <SwiperSlide>

              <section class="section " id="newsletters" ref={sectionRefs[4]}>
                <Box
                  sx={{
                    margin: 0,
                    padding: 0,
                    justifyContent: "cenetr",
                    alignSelf: "center",
                    display: "flex",
                  }}
                >
                  <NewsLetter onClickBusiness={onClickBusiness} onClick={onClick} visintingDetail={visintingDetail} />
                </Box>
              </section>
            </SwiperSlide>
          </Swiper>


          <Helmet>
            {/* <title>{metaTag?.title}</title> */}
            {/* <meta name="description" content={metaTag?.description} /> */}
            <script
              src="https://bizooda.s3.us-east-2.amazonaws.com/animation.js"
              async
            ></script>
            {/* <script
              src="animation.js"
              async
            ></script> */}
          </Helmet>
        </div >
      )
      }
    </>
  );
}

export default KastPreview;


const VerticalScrollBullet = ({ bulletlength, currentIndex }) => {
  return <div style={{ padding: 10, background: "rgba(0,0,0,0)", position: "fixed", top: 0, bottom: 0, right: 5, flex: 1 }}>
    <div style={{ background: "rgba(0,0,0,0)", flex: 1, display: "flex", flexDirection: "column", alignSelf: "flex-end", justifyContent: "flex-end", }}>
      {bulletlength?.map((item, index) => {
        return <div style={{ height: 10, width: 10, borderRadius: 10, background: bulletlength[currentIndex] === item ? "#729AFF" : "#dddddd", margin: 2 }} />
      })}
    </div>
  </div>
}
