import React from 'react'

function SquareIcon(props) {
    return (
        <div><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" fill={props.color} />
        </svg>
        </div>
    )
}

export default SquareIcon