import { ArrowDownward } from "@mui/icons-material";
import {
  Input,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
} from "@mui/material";
import React from "react";

export const WhiteBgInput = ({
  icon,
  placeholder,
  onChange,
  type,
  AdminInput,
  name,
  label
}) => {
  return (
    <>
      {(type == "file" || type == "color") && (
        <InputLabel
          style={{
            color: AdminInput ? "#000" : "#fff",
            fontSize: 12,
            fontWeight: "bold",
            marginBottom: -15,
          }}
          className="funnelFontFamily"
        >
          {label ?? ""}
        </InputLabel>
      )}
      <Input
        type={type}
        className="WhiteBgInput funnelFontFamily"
        fullWidth
        name={name}
        sx={{
          backgroundColor: AdminInput ? "#d1d1d1" : "#fff",
          borderRadius: 4,
          padding: 2,
          fontWeight: "bold",
          fontSize: 10,
        }}
        inputProps={{
          min: type == "number" ? 0 : null,
        }}
        placeholder={placeholder}
        onChange={onChange}
        startAdornment={icon}
      />
    </>
  );
};

export const WhiteBgSelect = ({
  options,
  onChange,
  value,
  placeholder,
  AdminInput,
}) => {
  return (
    <FormControl variant="standard">
      {placeholder && (
        <FormLabel
          style={{
            color: AdminInput ? "#fff" : "#fff",
            fontSize: 10,
            fontWeight: "bold",
            marginBottom: -15,
          }}
          className="funnelFontFamily"
        >
          {placeholder}
        </FormLabel>
      )}
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        className="WhiteBgInput funnelFontFamily"
        sx={{
          backgroundColor: AdminInput ? "#d1d1d1" : "#fff",
          borderRadius: 4,
          fontWeight: "bold",
          fontSize: 15,
          padding: 2
        }}
        value={value}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {options?.map((data, index) => {
          return (
            <MenuItem key={index} value={data?.value}>
              {data?.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
