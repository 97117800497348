import { Card, Grid, MenuItem, Paper, Select } from "@mui/material";
import React,{useState,useEffect} from "react";
import { OutlinedButtons, ContainedButtons } from "../../Component/Button";
import Alerts from "../../Component/Alerts";
import { InputField } from "../../Component/Input";
import { StandardSelectInput } from "../../Component/SelectInput";
import SwtichComponent from "../../Component/SwtichComponent";
import { DownArrow, GreyDownArrow } from "../../SVG/DownArrow";

import Table from "../../Component/Table";

import {
  RedCircleIcon,
  BlueCircleIcon,
  YellowCircleIcon,
} from "../../SVG/CircleIcon";
import DeleteIcon from "../../SVG/DeleteIcon";
import FilterIcon from "../../SVG/FilterIcon";
import { PenPaperIcon } from "../../SVG/PenPaperIcon";
import SearchIcon from "../../SVG/SearchIcon";
import SortIcon from "../../SVG/SortIcon";
import { useNavigate } from "react-router-dom";
import CalendarIcon from '../../SVG/CalendarIcon'
import PlusIcon from "../../SVG/PlusIcon";

import {AllTaskAction} from '../../Redux/Action/TaskAction'
import { useSelector, useDispatch } from 'react-redux';

function AllTask(props) {
  const navigate = useNavigate();
  const dispatch=useDispatch()

  const [taskData,setTaskData]=useState()

  const GetTaskData = () => {
    dispatch(AllTaskAction())
  }

  useEffect(() => {
    GetTaskData()

  }, [props])

  const state = useSelector(state => state.task?.Task?.data?.data);
  // //.log(state)

  useEffect(() => {

    setTaskData(state)

  }, [state])

  const columns = [
    { field: "id", headerName: "#No.", width: 130 },

    {
      field: "due_date",
      headerName: "Due Date",
      minWidth: 170,
    },

    {
      field: "subject",
      headerName: "Subject",
      minWidth: 170,
    },
    {
      field: "task_type",
      headerName: "Task Type",
      minWidth: 170,
      renderCell: (params) => {

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignContent: "center",
              minWidth: "100%",
            }}
          >
            <div>
              <Select
                variant="standard"
                value={params.row.task_type}
                disableUnderline
                IconComponent={() => <GreyDownArrow />}

                sx={{
                  border: "none",
                  height: 20,
                  borderRadius: "none",
                  display: "flex",
                }}
                // onChange={(e) => {

                //   changeLeadType(params.row.id, e.target.checked)
                // }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value='1'>High</MenuItem>
                <MenuItem value='2'>Medium</MenuItem>
                <MenuItem value='3'>Low</MenuItem>

              </Select>
            </div>

          </div>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 170,
    },

    {
      field: "",
      headerName: "",
      minWidth: 170,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>

            {/* <div
              style={{ padding: "12px" }}
              onClick={() => {
                navigate("./view-leads/edit");
              }}
            >
              <PenPaperIcon />
            </div> */}
            <div style={{ padding: "12px" }}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "warm",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 2,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "hot",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 3,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "hot",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 4,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "cool",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 5,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "warm",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 6,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "hot",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 7,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "cool",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 8,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "hot",
      TaskType: "Name",
      Time: "5 weeks",
    },
    {
      id: 9,
      DueDate: "21/12/2021",
      UserName: "Name",
      Subject: "cool",
      TaskType: "Name",
      Time: "5 weeks",
    },
  ];
  return (
    <div style={{ padding: "36px" }}>
      <Paper
        sx={{
          width: "100%",
          height: "1074px",
          background: "#FFFFFF",
          boxShadow: "-1px 2px 21px -5px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <div className="card-container">
          <div className="containerHead">
            <Grid container>
              <Grid item xs={10}>
                <p>All Tasks</p>
              </Grid>
              <Grid item xs={2} sx={{display:"flex",justifyContent:"center",alignItems:"right"}}>
                <div style={{ display: "flex", alignItems: "right", justifyContent: "flex-end",marginRight:""}}>
                  <p className="task-head-icon"><PlusIcon /></p>&nbsp;
                  <p className="task-head-icon"><CalendarIcon color="#46448D" /></p>
                </div>




              </Grid>
            </Grid>


          </div>

          <div className="containerContent">
            <div>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <InputField
                    input_id="search"
                    className="light-Textfield"
                    input_placeholder="Search Plans by name, date, company etc."
                    input_icon={<SearchIcon />}
                  />
                </Grid>
                {/* <Grid item xs={1}>
                  <OutlinedButtons color="#000000" button_name="Verify" />
                </Grid> */}
                <Grid item xs={2}>
                  <OutlinedButtons
                    color="#000000"
                    button_name="Filters"
                    button_icon={<SortIcon />}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <OutlinedButtons
                    color="#000000"
                    button_name="Verified Leads"
                  />
                </Grid> */}
              </Grid>
            </div>
            <div style={{ marginTop: 35 }}>
              <Table
                table_row={taskData?taskData:[]}
                table_columns={columns}
                table_checkbox={false}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default AllTask;
