import React from 'react'

function VisitingFacebook(props) {
    return (
        <div><svg width="10" height="18" viewBox="0 0 10 18" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66667 6.75H10V10.125H6.66667V18H3.33333V10.125H0V6.75H3.33333V5.33812C3.33333 4.0005 3.74889 2.31075 4.57556 1.38712C5.40222 0.46125 6.43444 0 7.67111 0H10V3.375H7.66667C7.11333 3.375 6.66667 3.82725 6.66667 4.38638V6.75Z" fill="white" />
        </svg>
        </div>
    )
}

export default VisitingFacebook