import {
    FetchCampaignConst, CreateCampaignConst, GetCampaignUserConst, GetNameAndIdConst,
    GetCampaignActivityByCampaignIdConst


} from '../Constant/constant';

const initTheme =
{
    FetchCampaignData: [],
    CreateCampaign: [],
    GetCampaignUserData: [],
    GetNameAndIdConstData: [],
    GetCampaignActivityByCampaignIdData:[]
};

const RecuitorFlowMarketing = (state = initTheme, action) => {
    //.log(state)
    switch (action.type) {

        case FetchCampaignConst:
            return { ...state, FetchCampaignData: action.payload }

        case CreateCampaignConst:
            return { ...state, CreateCampaign: action.payload }

        case GetCampaignUserConst:
            return { ...state, GetCampaignUserData: action.payload }

        case GetNameAndIdConst:
            return { ...state, GetNameAndIdConstData: action.payload }

            case GetCampaignActivityByCampaignIdConst:
            return { ...state, GetCampaignActivityByCampaignIdData: action.payload }



        default:
            return state;
    }
};

export default RecuitorFlowMarketing