// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-wj0XZJCoyuSSmgtzYBgQgR_0GVLH8mA",
  authDomain: "bizooda--auth.firebaseapp.com",
  projectId: "bizooda--auth",
  storageBucket: "bizooda--auth.appspot.com",
  messagingSenderId: "961149422840",
  appId: "1:961149422840:web:ec0a7719c6fc4784a93f0d"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider(); 
export {auth , provider};