import { Box } from "@mui/material"
import { KastListing } from "./kast-listing"
import React from "react"
import { getListingDetailsAPI } from "../../Redux/Services/listingService"
import { useParams } from "react-router-dom"

export const ListingSection = () => {
    const { id } = useParams();

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        getListingDetails()
    }, [])

    const getListingDetails = async () => {
        const response = await getListingDetailsAPI(id);
        console.log("listing detauls", response)
        setData(response.data.listings)
    }

    return <div
        className="kast-body non-scroll "
    >

        <section class="section non-scroll" style={{}}>
            <Box sx={{
                margin: 0,
                padding: 0,
                justifyContent: "cenetr",
                alignSelf: "center",
                display: "flex",
            }}
            >
                <KastListing data={data} />
            </Box>

        </section>

    </div >
}