import React from "react";

function VisitingTwitter(props) {
  return (
    <div>
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0073 2.21779C15.7704 1.74221 16.2757 1.09308 16.5231 0.267516C15.8262 0.650196 15.0783 0.928275 14.3028 1.09308C13.6389 0.368194 12.7984 0 11.7907 0C10.8057 0 9.97372 0.350935 9.29471 1.03363C8.96679 1.35782 8.70752 1.74657 8.53279 2.17607C8.35806 2.60556 8.27154 3.06676 8.27854 3.5314C8.27854 3.82768 8.31537 4.09232 8.38903 4.31669C5.48408 4.21601 3.07966 2.99253 1.18521 0.637628C0.864119 1.21389 0.703572 1.8007 0.703572 2.40764C0.703572 3.65509 1.22204 4.64365 2.2571 5.3695C1.66685 5.31868 1.14649 5.16718 0.703572 4.91884C0.703572 5.8096 0.961391 6.55366 1.47797 7.20855C1.97978 7.84669 2.68696 8.28496 3.47536 8.44641C3.18071 8.52312 2.87001 8.55955 2.5508 8.55955C2.25615 8.55955 2.04744 8.53462 1.92373 8.47997C2.14094 9.20581 2.5508 9.79453 3.14294 10.2452C3.72831 10.6965 4.44036 10.9461 5.17527 10.9576C3.91734 11.9596 2.47903 12.4553 0.851842 12.4553C0.453308 12.4553 0.170935 12.4496 0 12.4189C1.60263 13.4755 3.38659 14 5.36037 14C7.38042 14 9.16533 13.4803 10.7189 12.4409C12.2724 11.4073 13.4189 10.132 14.1555 8.6305C14.884 7.17759 15.2632 5.5702 15.2623 3.93987V3.49113C15.9413 2.98287 16.5291 2.35981 17 1.6492C16.3641 1.92905 15.6938 2.12031 15.0073 2.21779Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default VisitingTwitter;
