import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CustomizedTabs from './component/tabs';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SwipeDownThing } from './introduction';
import { ImagesearchRoller } from '@mui/icons-material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import EffectCarousel from "./component/effect-carousel.esm"
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import './component/effect-carousel.scss';
/**
 * Custom local styles
 */
import './component/main.scss';

import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/virtual';


const isMobile = window.innerWidth < 991
const isTablet = window.innerWidth < 768
const defaultImage = "https://cdn.vectorstock.com/i/preview-1x/52/88/no-image-symbol-shadow-missing-available-icon-vector-46405288.jpg"

export const FunnelPreview = ({ visintingDetail, mainFunnelData, onClick, onClickBusiness }) => {
    const items = visintingDetail.social_details.filter(_i => _i.type == "R")
    const itemsPerPage = isMobile ? 1 : 2;

    const [page, setPage] = React.useState(0);

    const maxPage = Math.ceil(items.length / itemsPerPage);

    const handlePrevious = () => {
        setPage(Math.max(0, page - 1));
    };

    const handleNext = () => {
        setPage(Math.min(maxPage - 1, page + 1));
    };

    return <Box sx={{ flex: 1, mx: 2, height: "100vh" }}>
        <Grid container justifyContent="center" alignSelf={"center"} className="intro">
            <Grid lg={6} item md={6} sm={12} xs={12} >
                <Box sx={{ justifyContent: "center", alignSelf: "center", }}>
                    <CustomizedTabs theme={visintingDetail.theme} setSelect={(e) => { e == 1 ? onClick() : onClickBusiness() }} />
                </Box>
            </Grid>
        </Grid>
        <Box sx={{ flex: 1 }} >


            <Grid container sx={{ mt: 5, flex: 1 }}>
                <Grid container xs={12} style={{ alignSelf: "center", flex: 1 }}>
                    <Grid xs={0} sm={0} lg={2} md={1} sx={{ justifyContent: "center", alignSelf: "center", textAlign: "center" }} >
                        {isMobile ? null : page == 0 ? null : <IconButton onClick={handlePrevious} disabled={page === 0}>
                            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.6" y="55" width="55" height="55" rx="27.5" transform="rotate(-90 0 55)" fill="#344C7E" />
                                <path d="M31.6468 35.6555C31.7897 35.5129 31.9031 35.3435 31.9805 35.157C32.0578 34.9705 32.0977 34.7705 32.0977 34.5686C32.0977 34.3667 32.0578 34.1668 31.9805 33.9803C31.9031 33.7938 31.7897 33.6244 31.6468 33.4818L25.6651 27.5001L31.6468 21.5184C31.9351 21.2302 32.097 20.8392 32.097 20.4316C32.097 20.0239 31.9351 19.6329 31.6468 19.3447C31.3585 19.0564 30.9676 18.8945 30.5599 18.8945C30.1523 18.8945 29.7613 19.0564 29.4731 19.3447L22.3968 26.4209C22.2539 26.5636 22.1405 26.733 22.0631 26.9195C21.9858 27.106 21.946 27.3059 21.946 27.5078C21.946 27.7097 21.9858 27.9096 22.0631 28.0961C22.1405 28.2826 22.2539 28.4521 22.3968 28.5947L29.4731 35.6709C30.0589 36.2568 31.0456 36.2568 31.6468 35.6555Z" fill="white" />
                            </svg>

                        </IconButton>}

                    </Grid>
                    <Grid xs={12} sm={12} lg={8} md={10}>
                        <div class="intro">
                            <Typography fontSize={isMobile ? 16 : 22} fontWeight={"600"} className='font-poppins' fontFamily={"Poppins"} color={"#fff"}>{"Free Downloads"}</Typography>
                        </div>

                        {isMobile ?
                            <div style={{ width: window.innerWidth - 30, marginTop: 30 }}>
                                <Swiper
                                    effect={'carousel'}
                                    grabCursor={true}
                                    modules={[Autoplay, EffectCarousel]}
                                    slidesPerView={2}
                                    loop
                                >                                {
                                        items.map(item => {
                                            return <SwiperSlide key={item.id}><ImageCrousel visintingDetail={visintingDetail} mainFunnelData={mainFunnelData} url={item.url} /></SwiperSlide>
                                        })
                                    }

                                </Swiper>
                            </div>
                            :
                            <Swiper
                                effect={'carousel'}
                                grabCursor={true}
                                modules={[Autoplay, EffectCarousel]}
                                loop
                                slidesPerView={2}

                            >

                                {items.map(item => {
                                    const url = item.url;
                                    return <SwiperSlide key={item.id}><ImageCrouselSmall visintingDetail={visintingDetail} mainFunnelData={mainFunnelData} url={item.url} /></SwiperSlide>
                                })}

                            </Swiper>


                        }
                    </Grid>

                </Grid>
            </Grid>
        </Box >
    </Box >



}


const ImageCrousel = ({ url, mainFunnelData, visintingDetail }) => {
    const image = mainFunnelData[url?.includes("/") ? url.split("/")[0] : url]?.uri == "" ? defaultImage : mainFunnelData[url?.includes("/") ? url.split("/")[0] : url]?.uri

    if (mainFunnelData) {
        return <Grid item xs={12} lg={6} bgcolor={""} onClick={() => { window.location.href = "/" + visintingDetail?.kast_name + `/${mainFunnelData[url.includes("/") ? url.split("/")[0] : url].data.url}/` }} sx={{ cursor: "pointer", flex: 1, justifyContent: "center", display: "flex", alignSelf: "center", background: "linear-gradient(rgb(14, 27, 52), rgb(31, 53, 102))" }} >
            <div style={isMobile ? { background: "linear-gradient(rgb(14, 27, 52), rgb(31, 53, 102))", borderRadius: 20 } : {}}>
                <Box sx={{ backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center", borderRadius: 5, display: "flex", flex: 1, alignSelf: "flex-end", justifyContent: "center", margin: 2, }}>
                    <div style={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.75))', height: isMobile ? 250 : 300, width: isMobile ? 250 : 300, borderRadius: 5, display: "flex", flexDirection: 'column', justifyContent: 'flex-end', flex: 1 }}>
                        <Typography fontSize={18} fontWeight={"800"} fontFamily={"Poppins"} sx={{ padding: 2 }} color={"#fff"}>{mainFunnelData[url.includes("/") ? url.split("/")[0] : url]?.title}</Typography>
                    </div>
                </Box >
            </div>
        </Grid >
    }
}
{/* background-image: linear-gradient(rgb(14, 27, 52), rgb(31, 53, 102)); */ }
const ImageCrouselSmall = ({ url, mainFunnelData, visintingDetail }) => {
    if (mainFunnelData) {
        return <Grid item xs={6} lg={12} bgcolor={"linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75))"} onClick={() => { window.location.href = "/" + visintingDetail?.kast_name + `/${mainFunnelData[url.includes("/") ? url.split("/")[0] : url].data.url}/` }} sx={{ borderRadius: 5, cursor: "pointer", flex: 1, justifyContent: "center", display: "flex", alignSelf: "center", background: visintingDetail.theme == "2" ? "linear-gradient(rgb(14, 27, 52), rgb(31, 53, 102))" : "linear-gradient(40deg, #4643BE , #8A58F5 )" }} >
            <Box sx={{ backgroundImage: `url(${mainFunnelData[url?.includes("/") ? url.split("/")[0] : url]?.uri})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", borderRadius: 5, display: "flex", flex: 1, alignSelf: "flex-end", justifyContent: "center", margin: 2, }}>
                <div style={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.75))', height: "350px", width: "100%", borderRadius: 5, display: "flex", flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Typography fontSize={18} fontWeight={"800"} fontFamily={"Poppins"} sx={{ padding: 2, textAlign: "center" }} color={"#fff"}>{mainFunnelData[url.includes("/") ? url.split("/")[0] : url]?.title}</Typography>
                </div>
            </Box >
        </Grid >

    }

}

// https://bizooda.s13.us-east-2.amazonaws.com/aaassets/Home%2BSeller%2BChecklist%2BEBook%2B(3).png