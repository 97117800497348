import { Grid, Paper } from "@mui/material";
import React, { useState,useEffect } from "react";
import RectangleIcon from "../../SVG/RectangleIcon";
import PenIcon from "../../SVG/PenIcon";
import PdfIcon from "../../SVG/PdfIcon";
import FetchIcon from "../../SVG/FetchIcon";
import GetLeadIcon from "../../SVG/GetLeadIcon";
import { useNavigate } from "react-router-dom";
import flatfileImportData from "../flatfileImportData";
import { FlatfileButton } from "@flatfile/react";

import { useSelector, useDispatch } from "react-redux";
import { ViewLeadsAction, GetAllSourceAction } from "../../Redux/Action/action";


function CreateNewLead() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [importedData, setImportedData] = useState()
  const [trueData, setTrueData] = useState()
  const [falseData, setFalseData] = useState()

  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    dispatch(GetAllSourceAction());
  }, []);

  const sourcestate = useSelector(
    (state) => state.auth?.GetAllSourceData.data?.data
  );

  useEffect(() => {
    setSourceData(sourcestate);
  }, [sourcestate]);



  const InsertBulkLead = () => {
 
     

    const result = importedData.map((o) => ({
      ...o,
      label: `is_arch`,
      value: `N`,
      
    }));
    const result1 = result.map((o) => ({
      ...o,
      label:'is_active',
      value:"Y",
      
    }));
    const result2 = result1.map((o) => ({
      ...o,
      label:'is_verified',
      value:"Y"
    }));
    console.log(result2)

  }
  return (
    <div
      style={{ paddingLeft: "123px", paddingRight: "79px", marginTop: "75px" }}
    >
      <Paper
        // elevation={4}
        sx={{
          background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
          boxShadow: "5px 7px 36px -6px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Grid
          spacing={2}
          sx={{ display: "flex", padding: 5, alignItems: "center" }}
        >
          <Grid item xs={10}>
            <div
              style={{
                fontWeight: 700,
                fontSize: "32px",
                lineHeight: "44px",
                marginBottom: 20,
              }}
            >
              This is how it works!
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "20px",
                lineheight: "150.4%",
              }}
            >
              Leads Information Leads Information Leads Information Leads
              Information Leads Information Leads Information Leads Information
              Leads Information Leads Information Leads Information Leads
              Information Leads Information
            </div>
          </Grid>
          <Grid item xs={2}>
            <RectangleIcon />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={4} sx={{ paddingRight: "69px" }} mt={2}>
        <Grid item xs={6}>
          <Paper
            onClick={() => {
              navigate("./create-new-lead/create");
            }}
            sx={{
              backgroundColor: `#00A79D`,
              borderRadius: "15px",
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <PenIcon />
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "41px",
                color: "#FFFFFF",
                marginTop: "28px",
              }}
            >
              Manual
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <div>
            <FlatfileButton
              settings={{
                type: "test import",
                fields: [
                  { label: "type", key: "type" },
                  { label: "first_name", key: "first_name" },
                  { label: "last_name", key: "last_name" },
                  // { label: "sourceId", key: "sourceId" },
                  { label: "lead_type", key: "lead_type" },
                  { label: "mobile_1", key: "mobile_1" },
                  { label: "mobile_2", key: "mobile_2" },
                  { label: "email", key: "email" },
                  { label: "address_1", key: "address_1" },
                  { label: "address_2", key: "address_2" },
                  { label: "full_address", key: "full_address" },
                  { label: "city", key: "city" },
                  { label: "state", key: "state" },
                  { label: "zip_code", key: "zip_code" },
                  { label: "source_id", key: "source_id" },
                  { label: "agent_id", key: "agent_id" },
                  { label: "listing_agent", key: "listing_agent" },
                  { label: "company_attorney", key: "company_attorney" },
                  { label: "loan_officer", key: "loan_officer" },
                  { label: "insurance_agent", key: "insurance_agent" },
                  { label: "label", key: "label" },
                  { label: "notes", key: "notes" },
                  { label: "price_range", key: "price_range" },
                  { label: "target_city", key: "target_city" },
                  { label: "closing_date", key: "closing_date" },
                  { label: "bedroom", key: "bedroom" },
                  { label: "bathroom", key: "bathroom" },
                  { label: "garage", key: "garage" },
                  { label: "custom_tag", key: "custom_tag" },
                  { label: "nurture", key: "nurture" },
                  { label: "tag", key: "tag" },
                  { label: "summary", key: "summary" },
                  { label: "openfield1", key: "openfield1" },
                  { label: "openfield2", key: "openfield2" },
                  { label: "openfield3", key: "openfield3" },
                  { label: "openfield4", key: "openfield4" },
                  { label: "openfield5", key: "openfield5" },
                  { label: "openfield5", key: "openfield5" },
                  { label: "openfield6", key: "openfield6" },
                  { label: "openfield7", key: "openfield7" },
                  { label: "openfield8", key: "openfield8" },
                  // { label: "openfield9", key: "openfield9" },
                  { label: "openfield9", key: "openfield9" },
                  { label: "openfield10", key: "openfield10" },

                ]
              }}
              licenseKey={"6560ab0c-4f39-4323-b3b3-04e5a9e08313"}
              customer={{
                userId: "12345"
              }}
              onData={async (results) => {
                setImportedData(results.data)
                InsertBulkLead()
                

                console.log(results);
              }}
              // onRecordChange={(record) =>
              //   return { name: { value: record.name + " from change" };
              // }}
              // onRecordInit={(record) =>
              //   return { name: { value: record.name + " from init" };
              // }}
              fieldHooks={{
                // email: (values) =>
                //   return values.map(([item, index]) => [
                //     { value: item + ".au" },
                //     index
                //   ]);
              }
              }
              onCancel={() => {
                console.log("cancel");
              }}
              render={(importer, launch) => {
                return <Paper
                  // id="flatfile-button"
                  onClick={launch}
                  sx={{
                    backgroundColor: "#746FBF",
                    borderRadius: "15px",
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <PdfIcon />
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "41px",
                      color: "#FFFFFF",
                      marginTop: "28px",
                    }}
                  >
                    Import
                  </div>
                </Paper>
              }}
            />
          </div>

        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              backgroundColor: "#CDAF79",
              borderRadius: "15px",
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <FetchIcon />
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "41px",
                color: "#FFFFFF",
                marginTop: "28px",
              }}
            >
              Fetch
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              backgroundColor: "#66BDC4",
              borderRadius: "15px",
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <GetLeadIcon />
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "41px",
                color: "#FFFFFF",
                marginTop: "28px",
              }}
            >
              Get Leads from Bizooda
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateNewLead;
