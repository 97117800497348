import React from "react";

function UpArrow() {
  return (
    <div>
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.2713 5.74809C0.0904331 5.58015 0 5.36641 0 5.10687C0 4.84733 0.0904331 4.63359 0.2713 4.46565L4.80942 0.251908C4.90807 0.160305 5.01495 0.0952674 5.13004 0.0567941C5.24514 0.0189315 5.36846 0 5.5 0C5.63154 0 5.75913 0.0229006 5.88278 0.0687021C6.00577 0.114504 6.10837 0.175572 6.19058 0.251908L10.7287 4.46565C10.9096 4.63359 11 4.84733 11 5.10687C11 5.36641 10.9096 5.58015 10.7287 5.74809C10.5478 5.91603 10.3176 6 10.0381 6C9.75859 6 9.5284 5.91603 9.34753 5.74809L5.5 2.17557L1.65247 5.74809C1.4716 5.91603 1.2414 6 0.961883 6C0.682362 6 0.452167 5.91603 0.2713 5.74809Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default UpArrow;
