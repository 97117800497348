import {
    AllTaskConst,AddTaskConst,GetDueDatesConst,GetTaskBasedOnDueDateTaskTypeConst,
    GetUserListConst


} from '../Constant/constant';

const initTheme =
{
    Task: [],
    addTask:[],
    GetDueDatesData:[],
    GetTaskBasedOnDueDateTaskTypeData:[],
    GetUserList:[]
};

const RecuitorFlowTask = (state = initTheme, action) => {
    //.log(state)
    switch (action.type) {

        case AllTaskConst:
            return { ...state, Task: action.payload }
            
        case AddTaskConst:
            return { ...state, addTask: action.payload }

        case GetDueDatesConst:
            return { ...state, GetDueDatesData: action.payload }

        case GetTaskBasedOnDueDateTaskTypeConst:
            return { ...state, GetTaskBasedOnDueDateTaskTypeData: action.payload }

            case GetUserListConst:
            return { ...state, GetUserList: action.payload }

        default:
            return state;
    }
};

export default RecuitorFlowTask
