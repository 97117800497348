import React from "react"
import { makeStyles } from "@mui/styles";
import { TextField, Button, Container } from '@mui/material';
import { AddScript } from "./add-script";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        maxWidth: '400px',
        width: '100%',
    },
    centerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "10%"
    },
}));

export const LoginAdmin = () => {
    const classes = useStyles();

    const [username, setUserName] = React.useState("vipin@bizooda.com");
    const [password, setPassword] = React.useState("Kast@4321");
    const [showScriptPage, setShowScriptPage] = React.useState(false);

    const handleSubmit = () => {
        if (username == "vipin@bizooda.com" && password == "Kast@4321") {
            setShowScriptPage(true)
        } else {
            alert("Details not correct try again.");
        }
    }

    return <>

        <Container className={classes.container}>
            <div className={classes.centerContent}>
                {showScriptPage ? <AddScript /> :
                    <form className={classes.form}>
                        <TextField onChange={(e) => setUserName(e.target.value)} label="Username" variant="outlined" />
                        <TextField onChange={(e) => setPassword(e.target.value)} label="Password" variant="outlined" type="password" />
                        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                            Login
                        </Button>
                    </form>
                }
            </div>
        </Container>
    </>
}