import React from "react";

function GetLeadIcon() {
  return (
    <div>
      <svg
        width="81"
        height="77"
        viewBox="0 0 81 77"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.1 5.4V18.9H35.1V5.4H62.1ZM62.1 0H35.1C33.6678 0 32.2943 0.568927 31.2816 1.58162C30.2689 2.59432 29.7 3.96783 29.7 5.4V18.9C29.7 20.3322 30.2689 21.7057 31.2816 22.7184C32.2943 23.7311 33.6678 24.3 35.1 24.3H62.1C63.5322 24.3 64.9057 23.7311 65.9184 22.7184C66.9311 21.7057 67.5 20.3322 67.5 18.9V5.4C67.5 3.96783 66.9311 2.59432 65.9184 1.58162C64.9057 0.568927 63.5322 0 62.1 0ZM21.6 56.7H5.4V35.1H21.6V29.7H5.4C3.96783 29.7 2.59432 30.2689 1.58162 31.2816C0.568927 32.2943 0 33.6678 0 35.1V56.7C0 58.1322 0.568927 59.5057 1.58162 60.5184C2.59432 61.5311 3.96783 62.1 5.4 62.1H21.6V56.7ZM18.9 5.4V18.9H5.4V5.4H18.9ZM18.9 0H5.4C3.96783 0 2.59432 0.568927 1.58162 1.58162C0.568927 2.59432 0 3.96783 0 5.4V18.9C0 20.3322 0.568927 21.7057 1.58162 22.7184C2.59432 23.7311 3.96783 24.3 5.4 24.3H18.9C20.3322 24.3 21.7057 23.7311 22.7184 22.7184C23.7311 21.7057 24.3 20.3322 24.3 18.9V5.4C24.3 3.96783 23.7311 2.59432 22.7184 1.58162C21.7057 0.568927 20.3322 0 18.9 0Z"
          fill="white"
        />
        <path
          d="M48.6 75.6002H75.6V70.2002H48.6V75.6002ZM48.6 59.4002H64.8V54.0002H48.6V59.4002ZM48.6 37.8002V43.2002H81V37.8002H48.6ZM37.8 36.4502C37.268 36.4504 36.7412 36.5553 36.2497 36.7591C35.7583 36.9629 35.3117 37.2614 34.9357 37.6378C34.5596 38.0141 34.2613 38.4608 34.0579 38.9524C33.8544 39.444 33.7498 39.9709 33.75 40.5029C33.7502 41.0349 33.8551 41.5617 34.0589 42.0532C34.2627 42.5446 34.5612 42.9912 34.9376 43.3672C35.3139 43.7433 35.7606 44.0416 36.2522 44.245C36.7438 44.4485 37.2707 44.5531 37.8027 44.5529C38.8772 44.5525 39.9075 44.1254 40.667 43.3653C41.4266 42.6053 41.8531 41.5747 41.8527 40.5002C41.8523 39.4257 41.4252 38.3954 40.6651 37.6359C39.9051 36.8763 38.8745 36.4498 37.8 36.4502ZM37.8 52.6502C37.268 52.6504 36.7412 52.7553 36.2497 52.9591C35.7583 53.1629 35.3117 53.4614 34.9357 53.8378C34.5596 54.2141 34.2613 54.6608 34.0579 55.1524C33.8544 55.644 33.7498 56.1709 33.75 56.7029C33.7502 57.2349 33.8551 57.7617 34.0589 58.2532C34.2627 58.7446 34.5612 59.1912 34.9376 59.5672C35.3139 59.9433 35.7606 60.2416 36.2522 60.445C36.7438 60.6485 37.2707 60.7531 37.8027 60.7529C38.8772 60.7525 39.9075 60.3253 40.667 59.5653C41.4266 58.8053 41.8531 57.7747 41.8527 56.7002C41.8523 55.6257 41.4252 54.5954 40.6651 53.8358C39.9051 53.0763 38.8745 52.6498 37.8 52.6502ZM37.8 68.8502C37.268 68.8504 36.7412 68.9553 36.2497 69.1591C35.7583 69.3629 35.3117 69.6614 34.9357 70.0378C34.5596 70.4141 34.2613 70.8608 34.0579 71.3524C33.8544 71.844 33.7498 72.3709 33.75 72.9029C33.7502 73.4349 33.8551 73.9617 34.0589 74.4532C34.2627 74.9446 34.5612 75.3911 34.9376 75.7672C35.3139 76.1433 35.7606 76.4416 36.2522 76.645C36.7438 76.8484 37.2707 76.9531 37.8027 76.9529C38.8772 76.9525 39.9075 76.5253 40.667 75.7653C41.4266 75.0053 41.8531 73.9747 41.8527 72.9002C41.8523 71.8257 41.4252 70.7954 40.6651 70.0358C39.9051 69.2763 38.8745 68.8498 37.8 68.8502Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default GetLeadIcon;
