import React from 'react'

function PhoneIconSvg() {
    return (
        <div><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.175 10.3167C13.15 10.3167 12.1583 10.15 11.2333 9.85C11.0884 9.80088 10.9326 9.79359 10.7837 9.82896C10.6349 9.86434 10.499 9.94094 10.3917 10.05L9.08333 11.6917C6.725 10.5667 4.51667 8.44167 3.34167 6L4.96667 4.61667C5.19167 4.38333 5.25833 4.05833 5.16667 3.76667C4.85833 2.84167 4.7 1.85 4.7 0.825C4.7 0.375 4.325 0 3.875 0H0.991667C0.541667 0 0 0.2 0 0.825C0 8.56667 6.44167 15 14.175 15C14.7667 15 15 14.475 15 14.0167V11.1417C15 10.6917 14.625 10.3167 14.175 10.3167Z" fill="black" />
        </svg>
        </div>
    )
}

export const Telephone=()=>{
    return(<div>

        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4257 9.61219C16.3341 8.98688 15.8426 8.49938 15.2097 8.35406C12.75 7.78781 12.1334 6.39656 12.0294 4.85344C11.5932 4.77375 10.8172 4.6875 9.50001 4.6875C8.18282 4.6875 7.40682 4.77375 6.97062 4.85344C6.86659 6.39656 6.24999 7.78781 3.79028 8.35406C3.15746 8.50031 2.6659 8.98688 2.57427 9.61219L2.09989 12.8391C1.93286 13.9753 2.78235 15 3.964 15H15.036C16.2167 15 17.0672 13.9753 16.9001 12.8391L16.4257 9.61219ZM9.50001 12.6487C8.1685 12.6487 7.08898 11.5988 7.08898 10.305C7.08898 9.01125 8.1685 7.96125 9.50001 7.96125C10.8315 7.96125 11.911 9.01125 11.911 10.305C11.911 11.5988 10.8306 12.6487 9.50001 12.6487ZM18.9971 3.75C18.9742 2.34375 15.33 0.0009375 9.50001 0C3.66907 0.0009375 0.0248467 2.34375 0.0028936 3.75C-0.0190595 5.15625 0.0229378 6.98625 2.42251 6.68156C5.22965 6.32437 5.05688 5.36156 5.05688 3.98531C5.05688 3.02531 7.34001 2.79375 9.50001 2.79375C11.66 2.79375 13.9422 3.02531 13.9431 3.98531C13.9431 5.36156 13.7704 6.32437 16.5775 6.68156C18.9761 6.98625 19.0191 5.15625 18.9971 3.75Z" fill="black"/>
</svg>
</div>


    )
}

export default PhoneIconSvg