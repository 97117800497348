import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';

export default function CustomizedTabs({ setSelect, theme }) {
    const [selected, setSelected] = React.useState(1)

    const handleClick = (val) => {
        setSelected(val)
    }
    React.useEffect(() => {
        if (selected != 1) {
            setSelect(selected)
        } setSelected(1)
    }, [selected])

    return (
        <Grid container sx={{ bgcolor: 'rba(0,0,0,0)', border: theme == "2" ? "0.5px solid #2C5097" : "0.5px solid #fff", borderRadius: 20, p: 0.5, display: "flex", my: 5, }}>
            <Grid item xs={6}>
                <Box sx={[{ background: selected == 1 ? "rgba(0,0,0,0)" : "linear-gradient(35deg, #0B172E, #344C7E)", }, style.tabs]}>
                    <Typography fontSize={17} fontWeight={"600"} onClick={() => handleClick(0)} sx={[style.tabText]} color={"#fff"}>{"Business Card"}</Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={[{ background: selected == 0 ? "rgba(0,0,0,0)" : theme == '2' ? "linear-gradient(35deg, #0B172E, #344C7E)" : "linear-gradient(40deg, #4643BE , #8A58F5 )", }, style.tabs]}>
                    <Typography fontSize={17} fontWeight={"600"} onClick={() => handleClick(1)} sx={[style.tabText]} color={"#fff"}>{"Resources"}</Typography>
                </Box>
            </Grid>
        </Grid >
    );
}

const style = {
    tabs: {
        borderRadius: 10
    },
    tabText: { cursor: "pointer", textAlign: "center", p: 0.5 }
}