import React from "react";
import { Grid, Typography, Stack, Button, useMediaQuery, Select, Checkbox, ListItemText } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { WhiteBgInput, WhiteBgSelect } from "../../../Component/WhiteBgInput";
import {
  EmailOutlined,
  PersonOutline,
  ExploreOutlined,
  ViewHeadlineOutlined,
} from "@mui/icons-material";
import { Formik } from "formik"
import * as Yup from "yup"
import { ImageUploadService, InsertAdminFunnelAPI, addFunnelToUser, getUserList } from "../../../Redux/Services/service";

const AdminFunnel = () => {
  const Width400 = useMediaQuery("(max-width:400px)");
  const Width600 = useMediaQuery("(max-width:600px)");
  const Width900 = useMediaQuery("(max-width:900px)");

  const [users, setUser] = React.useState([])
  const [show, setShow] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState([])
  const [isPublic, setIsPublic] = React.useState(false);

  const funnelTypeData = [
    { label: "Buyer", value: "Buyer" },
    { label: "Seller", value: "Seller" },
    { label: "Investor", value: "Investor" },
    { label: "General", value: "General" },
  ];

  const designTemplateData = [
    { label: "Branded funnel", value: "Branded funnel" },
  ];

  const permissionsAccessData = [
    { label: "Global Account", value: "Global Account" },
    { label: "Individual Account", value: "Individual Account" },
  ];

  const validateFrom = Yup.object().shape({
    funnel_name: Yup.string().required("Funnel Name Invalid Details provided"),
    funnel_type: Yup.string().required("Funnel type Invalid Details provided"),
    funnel_short_code: Yup.string().required("Short Code Invalid Details provided"),
    // funnel_template: Yup.string().required("Funnel Template Invalid Details provided"),
    funnel_link: Yup.string().required("Funnel link Invalid Details provided"),
    main_page_text: Yup.string().required("Main page Text Invalid Details provided"),
    primary_color: Yup.string().required("Primary Color Invalid Details provided"),
    headline_text: Yup.string().required("Headline Text Invalid Details provided"),
    sub_headline_text: Yup.string().required("Sub Heading Text Invalid Details provided"),
    hero_image_upload: Yup.string().required("Hero Image Upload Invalid Details provided"),
    button_text: Yup.string().required("Button text Invalid Details provided"),
    button_color: Yup.string().required("Button color Invalid Details provided"),
    preview_headline: Yup.string().required("Preview Headline Invalid Details provided"),
    preview_details: Yup.string().required("preview Details Invalid Details provided"),
  });

  const initialValues = {
    funnel_name: "",
    funnel_type: "Seller",
    funnel_short_code: "",
    funnel_template: "",
    funnel_link: "SellingInsiderSecrets",
    main_page_text: "",
    primary_color: "#000000",
    headline_text: "",
    sub_headline_text: "",
    hero_image_upload: "",
    button_text: "",
    button_color: "#000000",
    preview_headline: "",
    preview_details: "",
    created_by: "",
    pdf: "",
    isPublic: true
  }

  const uploadImage = (setField, e, key) => {
    let formData = new FormData();
    formData.append('image', e.target.files[0])
    ImageUploadService(formData).then((res) => {
      if (res.status === 200) {
        setField(key, res.data)
      }
    }).catch((err) => {
      console.log("res", err)
      alert("image upload fail please try again.")
    })
  }

  const submitForm = (values) => {
    values.created_by = "1"
    values.is_public = isPublic ? "Y" : "N"
    InsertAdminFunnelAPI(values).then(data => {
      console.log(data);
      if (data.data.code == 200) {
        console.log(data.data.data.id)
        const payload = {
          "funnel_id": data.data.data.id,
          "user_ids": selectedUser.map(data => data + ""),
          "created_by": "1"
        }
        addFunnelToUser(payload).then(val => {
          if (val.status == 200) {
            alert("Funnel Added");
            window.location.reload();
          } else {
            alert("We are facing issue with Please try again.");
          }
        })
      }
    })
  }

  const getAllUser = () => {
    getUserList().then(val => {
      console.log(val.data.users)
      setUser(val.data.users)
    })
  }

  React.useEffect(() => {
    getAllUser()
  }, [])



  return (
    <Grid container justifyContent={"center"}>
      <Formik initialValues={initialValues}
        validationSchema={validateFrom}
        onSubmit={values => {
          submitForm(values)
        }}>
        {({ errors, touched, handleChange, values, setFieldValue, handleSubmit }) => (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6} py={2} px={4}>
              <Typography
                color={"#fff"}
                className="funnelFontFamily"
                fontSize={20}
                fontWeight={"bold"}
              >
                {"-- Funnel Meta Data --"}
              </Typography>

              <Stack spacing={2} mt={2}>
                <WhiteBgInput
                  type={"text"}
                  placeholder="Funnel Name/Description"
                  onChange={handleChange("funnel_name")}
                  AdminInput={false}
                />
                <WhiteBgSelect
                  options={funnelTypeData}
                  onChange={handleChange("funnel_type")}
                  value={"Seller"}
                  placeholder={"Funnel Type"}
                  AdminInput={false}
                />
                <WhiteBgInput
                  type={"text"}
                  placeholder="Funnel Short Code"
                  onChange={handleChange("funnel_short_code")}
                  AdminInput={false}
                />
                <WhiteBgSelect
                  options={designTemplateData}
                  onChange={handleChange("funnel_template")}
                  value={"Design1"}
                  placeholder={"Funnel Template"}
                  AdminInput={false}
                />
                <WhiteBgInput
                  type={"text"}
                  placeholder="Funnel link"
                  onChange={handleChange("funnel_link")}
                  AdminInput={false}
                />
              </Stack>
              <Typography
                color={"#fff"}
                className="funnelFontFamily"
                fontSize={20}
                fontWeight={"bold"}
                AdminInput={false}
                mt={2}
              >
                {"-- Funnel Design Elements --"}
              </Typography>

              <Stack spacing={2} mt={2}>
                <WhiteBgInput
                  type={"text"}
                  placeholder="Main page Text"
                  onChange={handleChange("main_page_text")}
                  AdminInput={false}
                />

                <WhiteBgInput
                  type={"color"}
                  placeholder="Primary color "
                  onChange={handleChange("primary_color")}
                  AdminInput={false}
                  label="primary color"
                />

                <WhiteBgInput
                  type={"text"}
                  placeholder="Headline text"
                  onChange={handleChange("headline_text")}
                  AdminInput={false}
                />
                <WhiteBgInput
                  type={"text"}
                  placeholder="Sub Headline Text"
                  onChange={handleChange("sub_headline_text")}
                  AdminInput={false}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} py={2} px={4}>
              <Typography
                color={"#fff"}
                className="funnelFontFamily"
                fontSize={20}
                fontWeight={"bold"}
              >
                {"-- Form Design Fields --"}
              </Typography>

              <Stack spacing={2} mt={2}>
                <WhiteBgInput
                  type={"file"}
                  placeholder="Hero Image upload"
                  onChange={(e) => uploadImage(setFieldValue, e, "hero_image_upload")}
                  AdminInput={false}
                  label={"Hero Image upload"}
                />

                <WhiteBgInput
                  type={"file"}
                  placeholder="Branded PDF upload"
                  onChange={(e) => uploadImage(setFieldValue, e, "pdf")}
                  AdminInput={false}
                  label={"Branded PDF upload"}
                />

                <WhiteBgInput
                  type={"text"}
                  icon={<ViewHeadlineOutlined style={{ marginRight: 10 }} />}
                  placeholder="Button text"
                  onChange={handleChange("button_text")}
                  AdminInput={false}
                />
                <WhiteBgInput
                  type={"color"}
                  icon={<ViewHeadlineOutlined style={{ marginRight: 10 }} />}
                  placeholder="Button color"
                  onChange={handleChange("button_color")}
                  AdminInput={false}
                  label="Button Color"
                />
                <WhiteBgInput
                  type={"text"}
                  icon={<ViewHeadlineOutlined style={{ marginRight: 10 }} />}
                  placeholder="Preview Headline"
                  onChange={handleChange("preview_headline")}
                  AdminInput={false}
                />
                <WhiteBgInput
                  type={"text"}
                  icon={<ViewHeadlineOutlined style={{ marginRight: 10 }} />}
                  placeholder="Preview Details"
                  onChange={handleChange("preview_details")}
                  AdminInput={false}
                />

                <WhiteBgSelect
                  options={permissionsAccessData}
                  onChange={(e) => setIsPublic(!isPublic)}
                  value={isPublic ? "Global Account" : "Individual Account"}
                  placeholder={"Funnel Permissions Access"}
                  AdminInput={false}
                />

                {isPublic ? null :
                  <Select value={selectedUser} style={{ backgroundColor: "#fff" }} multiple
                    renderValue={(selected) => users.map(data => (selected.includes(data.user_id) ? data["user_name"] : "")).filter(data => data != "").join(", ")}
                    onChange={(e) => {
                      setSelectedUser(e.target.value)
                    }}>
                    {users.map(data => {
                      return <MenuItem key={data.id} value={data["user_id"]}>
                        <Checkbox checked={selectedUser.includes(data["user_id"])} />
                        <ListItemText primary={data["user_name"]} />
                      </MenuItem>
                    })}
                  </Select>}
                {Object.keys(errors).map((data, index) => {
                  console.log(errors)
                  return <Typography color={"#ff0000"} fontSize={12}>{"* " + errors[data]}</Typography>
                })}

                <Button variant="contained" onClick={handleSubmit} >Save</Button>
              </Stack>
            </Grid>

          </>
        )}
      </Formik>

    </Grid >
  );
};

export default AdminFunnel;
