export const UserLoginConst = 'UserLogin'
export const UserSignupConst = 'UserSignup'
export const ViewLeadsConst = 'ViewLeads'
export const AllVerifiedLeadsConst = 'AllVerifiedLeads'
export const AllUnVerifiedLeadsConst = 'AllUnVerifiedLeads'
export const ArchiveLeadsConst = 'ArchiveLeads'
export const AddLeadConst = 'AddLead'
export const AddFromBizoodaLeadConst = 'AddFromBizoodaLead'
export const UserEmailPasswordSignupConst = 'UserEmailPasswordSignup'
export const BasicInformationFromConst = 'BasicInformationFrom'
export const DetailedInformationFormConst ='DetailedInformationForm'
export const AgentInformationFormConst='AgentInformationForm'
export const LeadRequirementFormConst='LeadRequirementForm'
export const AddNewLeadConst="AddNewLead"


// get all data api constant
export const GetLeadByIdConst="GetLeadById"
export const GetAllNurtureConst="GetAllNurture"
export const GetAllInsuranceAgentConst="GetAllInsuranceAgent"
export const GetAllClosingAgentConst="GetAllClosingAgent"
export const GetAllCompanyAttorneyConst="GetAllCompanyAttorney"
export const GetAllLoanAgentConst="GetAllLoanAgent"
export const GetAllAgentConst="GetAllAgent"
export const GetAllSourceConst="GetAllSource"
export const GetAllTagConst="GetAllTag"
export const GetDashBoardConst="GetDashBoard"


// task 
export const AllTaskConst = 'AllTask'
export const AddTaskConst = 'AddTask'
export const GetDueDatesConst="GetDueDates"
export const GetTaskBasedOnDueDateTaskTypeConst="GetTaskBasedOnDueDateTaskType"
export const GetUserListConst="GetUserList"

// marketing
export const FetchCampaignConst="FetchCampaign"
export const CreateCampaignConst="CreateCampaign"
export const GetCampaignUserConst="GetCampaignUser"
export const GetNameAndIdConst="GetNameAndId"
export const GetCampaignActivityByCampaignIdConst="GetCampaignActivityByCampaignId"


