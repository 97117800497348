import "./App.css";
import BasicPage from "./Pages/BasicPage";
import ComponentPage from "./Pages/ComponentPage";
import FunnelPage1 from "./Pages/Funnels/FunnelPage1";
import FunnelPage2 from "./Pages/Funnels/FunnelPage2";
import FunnelPage3 from "./Pages/Funnels/FunnelPage3";
import Routing from "./Routing/Routing";
// import Login from '/../Pages/Login'
// import Funn




function App() {

  return <Routing />;
}

export default App;
