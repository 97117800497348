import { combineReducers } from 'redux';
import RecuitorFlowAuth from "./authentication";
import RecuitorFlowTask from './task'
import RecuitorFlowMarketing from './marketing'


export const reducers = combineReducers({
    auth: RecuitorFlowAuth,
    task:RecuitorFlowTask,
    marketing:RecuitorFlowMarketing
});