export const darkenHexColor = (hex, factor) => {
    // Validate hex input
    hex = hex.replace(/^#/, '');
    if (hex.length !== 6) {
        throw new Error('Invalid hex color code');
    }

    // Convert hex to RGB
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    // Darken the color by applying a factor (0.1 means 10% darker)
    r = Math.round(r * (1 - factor));
    g = Math.round(g * (1 - factor));
    b = Math.round(b * (1 - factor));

    // Ensure values are within valid range (0-255)
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    // Convert back to hex
    const darkenedHex = (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);

    return darkenedHex;
}